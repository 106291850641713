.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1300px;
  position: relative;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 0px 24px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__cOh6P {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.column__cOh6P > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.column__cOh6P > :global(.__wab_flex-container) > *,
.column__cOh6P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__cOh6P > :global(.__wab_flex-container) > picture > img,
.column__cOh6P
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  height: 24px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  min-width: 0;
}
.column___5MsA7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
