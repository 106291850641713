.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1300px;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 15px);
  width: calc(100% + 15px);
  --plsmc-rc-col-gap: 15px;
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 15px;
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__qLvTu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__wcigm {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: none;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 300px;
  width: 100%;
  height: 400px;
  transform: none;
  filter: contrast(109%) grayscale(100%);
  box-shadow: none;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 2px;
}
.img > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img {
    height: 300px;
    object-fit: contain;
    max-width: auto;
    flex-shrink: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .img > picture > img {
    object-fit: contain;
  }
}
.column__koT0K {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__hkWEd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  z-index: 2;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__kd40A {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: -40px;
  margin-left: -200px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__kd40A {
    margin: auto;
  }
}
.freeBox__ivrGb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetQuote {
  font-size: 1.2em;
  font-weight: 300;
  color: #ffffff;
  line-height: 1.5;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetQuote {
    font-size: 1em;
  }
}
.freeBox__qz2K {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 43px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetName {
  font-size: 1.8em;
  font-weight: 500;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetName {
    font-size: 1.5em;
  }
}
.freeBox__vVrMv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 580px;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTitle {
  font-size: 1.2em;
  font-weight: 200;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTitle {
    font-size: 1em;
  }
}
