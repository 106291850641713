.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  min-width: 0;
  padding: 8px 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    min-height: 50vh;
  }
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin-left: 0px;
  z-index: 10;
  min-width: 0;
  padding: 8px 8px 8px 93px;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox {
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 0px;
    transform: translateX(0px) translateY(0px) translateZ(0px);
  }
}
.text__dV1Df {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 14px;
  min-width: 0;
}
.text__iWVx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 5em;
  font-weight: 700;
  line-height: 1.2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__iWVx {
    font-size: 2.5em;
    line-height: 1.1;
  }
}
.text___6A9Xb {
  position: relative;
  width: 100%;
  height: auto;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 500;
  min-width: 0;
  padding: 0px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___6A9Xb {
    font-size: 1em;
    font-weight: 300;
  }
}
.link {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #ffa500;
  margin-top: calc(13px + 12px) !important;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
  padding: 8px 36px;
}
.root .link:hover {
  background: #fdaa12;
  transform: translateX(0px) translateY(-2px) translateZ(0px);
  margin-top: calc(13px + 12px) !important;
}
.text__wtX1U {
  width: auto;
  height: auto;
  max-width: 100%;
  color: #ffffff;
}
.mousedown {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  left: 0;
  z-index: 1;
  top: auto;
  right: 0;
  bottom: 0;
  min-height: 100px;
  padding: 8px;
}
