.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___8JE3I {
  box-shadow: 0px 8px 16px 4px #00000026;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 600px;
  background: #262626;
  transition-property: all;
  transition-duration: 0.8s;
  cursor: pointer;
  min-height: 600px;
  min-width: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.8s;
  border-radius: 6px;
}
.root .freeBox___8JE3I:hover {
  transform: translateX(0px) translateY(-4px) translateZ(0px);
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 100%;
  backdrop-filter: none;
  filter: none;
  box-shadow: none;
  min-height: 200px;
  min-width: 0;
  -webkit-backdrop-filter: none;
}
.img > picture > img {
  object-fit: contain;
}
.freeBox___4122Y {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 16px;
  min-width: 0;
  padding: 18px 24px 36px;
}
.freeBox__xep7F {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetPreface {
  font-weight: 300;
  color: #ffa500;
  font-size: 0.8em;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetPreface {
    font-size: 0.9em;
  }
}
.freeBox__xUrve {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTitle {
  font-size: 1.4em;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.2;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTitle {
    font-size: 1.4em;
  }
}
.freeBox__n3MX1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetDescription {
  font-size: 0.9em;
  font-weight: 300;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetDescription {
    font-size: 0.9em;
  }
}
.getStartedWithArrow:global(.__wab_instance) {
  position: relative;
  width: auto;
  left: auto;
  z-index: 1;
  align-self: auto;
  top: auto;
  margin-top: 40px;
}
