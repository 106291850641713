.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
}
.caseStudyColumns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.caseStudyColumns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.caseStudyColumns > :global(.__wab_flex-container) > *,
.caseStudyColumns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.caseStudyColumns > :global(.__wab_flex-container) > picture > img,
.caseStudyColumns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 20px;
}
.caseStudyColumns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.caseStudyColumns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyColumns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyColumns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 60px);
    height: calc(100% + 60px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyColumns > :global(.__wab_flex-container) > *,
  .caseStudyColumns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .caseStudyColumns > :global(.__wab_flex-container) > picture > img,
  .caseStudyColumns
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 60px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyColumns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__gOjty {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.solutionItem__mWzol:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___5RMoR {
    font-size: 18px;
    font-weight: 500;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__fUzY {
    font-size: 14px;
  }
}
.column__wukKv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.solutionItem__w6VxY:global(.__wab_instance) {
  position: relative;
}
.column__dKps9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.solutionItem___5Gj1W:global(.__wab_instance) {
  position: relative;
}
.column__jZPlV {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.solutionItem__xbfMq:global(.__wab_instance) {
  position: relative;
}
