.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.freeBox {
  width: auto;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.slotTargetTitle {
  font-weight: 300;
  color: #ffac2b;
}
.root:hover .slotTargetTitle {
  color: #ff9c02;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #ffac2b;
  transition-property: all;
  transition-duration: 0.4s;
  height: 1em;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.4s;
}
.root:hover .svg {
  color: #ff9c02;
  transform: translateX(5px) translateY(0px) translateZ(0px);
}
