.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 450px;
  background: linear-gradient(130deg, #ffa5003b 0%, #1d1d1d00 59%) 0% 0% / 100%
    100% repeat;
  min-height: 400px;
  position: relative;
  min-width: 0;
  border-radius: 20px;
  padding: 16px 30px;
}
.rootblue {
  background: linear-gradient(210deg, #4d33964d 5%, #1d1d1d00 59%) 0% 0% / 100%
    100% repeat;
}
.rootred {
  background: linear-gradient(290deg, #ff57224d 7%, #1d1d1d00 62%) 0% 0% / 100%
    100% repeat;
}
.freeBox___0Ow06 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 200px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__ljxxs {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ljxxs > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__ljxxs > :global(.__wab_flex-container) > *,
.freeBox__ljxxs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ljxxs > :global(.__wab_flex-container) > picture > img,
.freeBox__ljxxs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.text {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.2;
  font-style: italic;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    font-size: 3em;
  }
}
.freeBox__wdKmU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetChildren {
  font-size: 2em;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetChildren {
    font-size: 2em;
  }
}
.freeBox__gO8Gu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__gO8Gu {
    margin-top: 8px;
  }
}
.slotTargetSlot {
  font-size: 0.9em;
  font-weight: 300;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetSlot {
    font-size: 1em;
  }
}
