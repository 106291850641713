.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.line2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-shadow: none;
  height: 1px;
  min-width: 0;
  flex-shrink: 0;
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  min-width: 0;
}
.img > picture > img {
  object-fit: contain;
}
.freeBox__lM9Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTitle {
  font-size: 2em;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTitle {
    font-size: 2em;
  }
}
.freeBox__eRUx4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetDescription {
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.8;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetDescription {
    font-size: 1em;
  }
}
