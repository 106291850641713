@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #adacac;
  background-color: #adacac;
  cursor: pointer;
}

@keyframes slideFade {
  0% {
    opacity: 0;
    transform: translateY(-50px); /* Start above its final position */
  }
  50% {
    opacity: 1;
    transform: translateY(0px); /* Move to final position */
  }
  100% {
    opacity: 0;
    transform: translateY(50px); /* Move below its final position */
  }
}

/*.scroll-indicator {*/
/*  position: absolute;*/
/*  bottom: 35px; !* Adjusted for visual clarity at the bottom *!*/
/*  left: 118px;*/
/*  transform: translateX(-50%);*/
/*  width: 2px; !* Making the line thin *!*/
/*  height: 30px; !* Height of the line as specified *!*/
/*  background-color: white;*/
/*  animation: slideFade 3s infinite;*/
/*  z-index: 20;*/
/*  border-radius: 500px;*/
/*}*/

/* CSS for fade-out animation */
.scroll-indicator-mouse {
  transition: opacity 3s ease-in-out;
  opacity: 1;
}

.scroll-indicator-mouse.fade-out {
  opacity: 0;
}

@media (max-width: 767px) {
  .scroll-indicator {
    display: none;
  }
}


/* black and white --> color */
.feature-cards img {
  filter: grayscale(100%);
  transition: filter 0.3s;
}

/* on hover make color */
.feature-cards:hover img {
  filter: grayscale(0%);
}


/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .feature-cards img {
    filter: grayscale(0%);
  }

  /* No grayscale on hover for mobile */
  .feature-cards:hover img {
    filter: grayscale(0%);
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
/* text fades in 0.7s every time it changes due to react state */
.custom-text-fade-in {
  animation: fadeIn 0.7s;
}

/* FAQ section arrowcolor */
.ant-collapse-expand-icon {
  color: #f0f0f0;
}

.ant-collapse-header-text {
  color: #ffffff
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.spinning {
  animation: spin 0.4s ease-out;
}

.faq-label {
  color: #ffffff;
  transition: color 0.3s;
}

.faq-label-clicked {
  color: #FFA500;
}

/* Style for the user-entered text in antd textarea */
.contact-message-box {
  color: #ffffff;  /* White color for user-entered text */
}

.contact-message-box::placeholder {
  color: #706F6C;  /* Dark grey color for placeholder */
}

.animated-number {
  font-size: 7rem;
}

@media (max-width: 768px) {
  .animated-number {
    font-size: 6rem;
  }
}


.slick-dots li button:before {
  color: #c4c9d5 !important;
}

.slick-dots li.slick-active button:before {
  color:#FFA500!important;
}