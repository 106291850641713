.root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
}
.freeBox__pWGpf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(70deg, #d48100 0%, #f79e14 100%);
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
  padding: 8px 36px;
}
.freeBoxclicked__pWGpfGHxge {
  display: none;
}
.root:hover .freeBox__pWGpf {
  background: linear-gradient(80deg, #b06b00 0%, #d48100 100%);
  transform: translateX(0px) translateY(-2px) translateZ(0px);
}
.freeBox__iKAn0 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
}
.slotTargetText {
  color: #ffffff;
}
.loadingDiv {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 60px;
  max-width: 60px;
  display: none;
  margin: 0px 0px 0px 8px;
}
.loadingDivloading {
  display: flex;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 0.9em;
  min-width: 0;
  display: none;
}
.textclicked {
  display: block;
}
