.root {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  min-width: 0;
  padding: 0px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    justify-content: space-between;
  }
}
.link__cQfLd {
  position: relative;
  display: flex;
  left: auto;
  top: auto;
  justify-content: flex-start;
  align-items: center;
}
.img {
  object-fit: contain;
  max-width: 100%;
  width: 35px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.freeBox__wlic3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
}
.freeBox__rch19 {
  display: flex;
  flex-direction: row;
  height: auto;
  max-width: 125px;
}
.freeBox__rch19 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.freeBox__rch19 > :global(.__wab_flex-container) > *,
.freeBox__rch19 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rch19 > :global(.__wab_flex-container) > picture > img,
.freeBox__rch19
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.link__lkVwa {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  color: #dddddd;
  transition-property: all;
  transition-duration: 0.4s;
  white-space: pre;
  cursor: pointer;
  transform: translateX(0px) translateY(2px) translateZ(0px);
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.4s;
  padding: 4px;
}
.root .link__lkVwa:hover {
  color: #ffffff;
  transform: translateX(0px) translateY(0px) translateZ(0px);
}
.menu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 45px;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
}
