.root {
  box-shadow: 0px 8px 16px 4px #00000026;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 600px;
  background: #262626;
  position: relative;
  min-width: 0;
  border-radius: 6px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 270px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 16px;
  min-width: 0;
  padding: 18px 24px 36px;
}
.text__smsqe {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-P0Hg_5iFQxKi);
  font-weight: 300;
  min-width: 0;
}
.text__iDgtr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 16px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__iDgtr {
    font-size: 3em;
  }
}
.text__rzHHn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 1em;
  font-weight: 300;
  margin-top: 16px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__rzHHn {
    font-size: 1.5em;
  }
}
