.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  background: #171717;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-height: 100vh;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px;
}
.navBarCustom:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.ctaParent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
