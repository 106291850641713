.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px 12px;
}
.whoAreYou {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 1400px;
  min-width: 0;
  padding: 0px 20px;
}
.whoAreYou > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.whoAreYou > :global(.__wab_flex-container) > *,
.whoAreYou > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.whoAreYou > :global(.__wab_flex-container) > picture > img,
.whoAreYou
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 40px;
}
.text__zqKYw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 2.5em;
  font-weight: 500;
  margin-top: calc(0px + 40px) !important;
  text-align: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__zqKYw {
    font-size: 1.5em;
    margin-top: calc(0px + 40px) !important;
  }
}
.texttopBoxesHidden__zqKYwSwsxk {
  margin-top: calc(0px + 40px) !important;
  display: none;
}
.orgOptionsSection {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.orgOptionsSection > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
}
.orgOptionsSection > :global(.__wab_flex-container) > *,
.orgOptionsSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.orgOptionsSection > :global(.__wab_flex-container) > picture > img,
.orgOptionsSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.orgOptionsSection > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.orgOptionsSection > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.orgOptionsSection > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .orgOptionsSection {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .orgOptionsSection > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .orgOptionsSection > :global(.__wab_flex-container) > *,
  .orgOptionsSection
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .orgOptionsSection > :global(.__wab_flex-container) > picture > img,
  .orgOptionsSection
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 30px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .orgOptionsSection > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.orgOptionsSectiontopBoxesHidden {
  display: none;
}
.column__skbAs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.column___3Q69S {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.column__c1GGq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.ctaSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
  margin: 60px 0px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > *,
  .columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns > :global(.__wab_flex-container) > picture > img,
  .columns
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 30px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__vGpn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 36px 0px;
}
.freeBox__pXaji {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__pXaji > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 18px);
  height: calc(100% + 18px);
}
.freeBox__pXaji > :global(.__wab_flex-container) > *,
.freeBox__pXaji > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pXaji > :global(.__wab_flex-container) > picture > img,
.freeBox__pXaji
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 18px;
}
.text__mB7Yw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 700;
  margin-top: calc(0px + 18px) !important;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__mB7Yw {
    font-size: 1.5em;
    margin-top: calc(0px + 18px) !important;
  }
}
.text__jGR0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 480px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
  margin-top: calc(0px + 18px) !important;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__jGR0 {
    font-size: 1em;
    margin-top: calc(0px + 18px) !important;
  }
}
.column__yiu6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background: #313131;
  box-shadow: 0px 4px 16px 4px rgba(0, 0, 0, 0.2);
  min-width: 0;
  border-radius: 8px;
  padding: 36px 24px;
}
.text__yvMvu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 700;
  margin-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__yvMvu {
    font-size: 1.5em;
  }
}
.freeBox__naFEx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 27px;
  min-width: 0;
}
.freeBox__naFEx > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 28px);
  height: calc(100% + 28px);
}
.freeBox__naFEx > :global(.__wab_flex-container) > *,
.freeBox__naFEx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__naFEx > :global(.__wab_flex-container) > picture > img,
.freeBox__naFEx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 28px;
}
.freeBox__vdPx {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: calc(0px + 28px) !important;
  min-width: 0;
}
.freeBox__vdPx > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.freeBox__vdPx > :global(.__wab_flex-container) > *,
.freeBox__vdPx > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vdPx > :global(.__wab_flex-container) > picture > img,
.freeBox__vdPx
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
.firstName:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pdUv4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__rpVaT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.lastName:global(.__wab_instance) {
  max-width: 100%;
}
.svg__efdwM {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aVlvg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: calc(0px + 28px) !important;
}
.svg__qlxxq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3Sk1N {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.industry:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: calc(0px + 28px) !important;
}
.svg__eszg4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__ygyvR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.message {
  position: relative;
  width: 525px;
  height: auto;
  max-width: 100%;
  align-self: flex-end;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #575757;
}
.connectButton:global(.__wab_instance) {
  position: relative;
  margin-top: 41px;
}
