.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  min-height: 250px;
  box-shadow: none;
  min-width: 0;
}
.freeBox__kz0Ed {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: none;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 6px;
}
.root .freeBox__kz0Ed:hover {
  transform: translateX(0px) translateY(0px) translateZ(0px) translateX(0px)
    translateY(-4px) translateZ(0px);
  border: 1px solid #335ab8;
}
.freeBox__vHpE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 140px;
  max-width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__spZnt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  min-height: 60px;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px 16px;
}
.slotTargetTitle {
  font-size: 1.2em;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTitle {
    font-size: 1.5em;
  }
}
