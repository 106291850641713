@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&family=Inconsolata%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&display=swap");

.plasmic_tokens {
  --token-P0Hg_5iFQxKi: #ffa500;
  --plasmic-token-primary-1: var(--token-P0Hg_5iFQxKi);
  --token-vu-ggukaZq30: #262626;
  --plasmic-token-background-1: var(--token-vu-ggukaZq30);
  --token-C2VTKBswLrNI: #ffa500;
  --plasmic-token-primary-2: var(--token-C2VTKBswLrNI);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-BmsHYC2Qg5jM_font-family: "Inter", sans-serif;
  --mixin-BmsHYC2Qg5jM_font-size: 16px;
  --mixin-BmsHYC2Qg5jM_font-weight: 400;
  --mixin-BmsHYC2Qg5jM_font-style: normal;
  --mixin-BmsHYC2Qg5jM_color: #535353;
  --mixin-BmsHYC2Qg5jM_text-align: left;
  --mixin-BmsHYC2Qg5jM_text-transform: none;
  --mixin-BmsHYC2Qg5jM_line-height: 1.5;
  --mixin-BmsHYC2Qg5jM_letter-spacing: normal;
  --mixin-BmsHYC2Qg5jM_white-space: pre-wrap;
  --mixin-BmsHYC2Qg5jM_user-select: text;
  --mixin-BmsHYC2Qg5jM_text-decoration-line: none;
  --mixin-BmsHYC2Qg5jM_text-overflow: clip;
  --mixin-GD1oTm4vG2YR_color: #000000;
  --mixin-GD1oTm4vG2YR_font-weight: 700;
  --mixin-GD1oTm4vG2YR_font-size: 64px;
  --mixin-GD1oTm4vG2YR_line-height: 1;
  --mixin-GD1oTm4vG2YR_letter-spacing: -1px;
  --mixin-GD1oTm4vG2YR_white-space: pre-wrap;
  --mixin-Cyvgacm50yjX_color: #000000;
  --mixin-Cyvgacm50yjX_font-size: 48px;
  --mixin-Cyvgacm50yjX_font-weight: 700;
  --mixin-Cyvgacm50yjX_letter-spacing: -0.5px;
  --mixin-Cyvgacm50yjX_line-height: 1.1;
  --mixin-Cyvgacm50yjX_white-space: pre-wrap;
  --mixin-nR0BcGDE1UIJ_color: #0070f3;
  --mixin-nR0BcGDE1UIJ_white-space: pre-wrap;
  --mixin-JcaHpWWZngGr_color: #000000;
  --mixin-JcaHpWWZngGr_font-size: 32px;
  --mixin-JcaHpWWZngGr_font-weight: 600;
  --mixin-JcaHpWWZngGr_line-height: 1.2;
  --mixin-JcaHpWWZngGr_white-space: pre-wrap;
  --mixin-bvOR7Z94IU3Z_color: #000000;
  --mixin-bvOR7Z94IU3Z_font-size: 24px;
  --mixin-bvOR7Z94IU3Z_font-weight: 600;
  --mixin-bvOR7Z94IU3Z_line-height: 1.3;
  --mixin-bvOR7Z94IU3Z_white-space: pre-wrap;
  --mixin-GuGA5Dbb1eJ7_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-GuGA5Dbb1eJ7_border-bottom-color: #dddddd;
  --mixin-GuGA5Dbb1eJ7_border-bottom-style: solid;
  --mixin-GuGA5Dbb1eJ7_border-bottom-width: 1px;
  --mixin-GuGA5Dbb1eJ7_border-left-color: #dddddd;
  --mixin-GuGA5Dbb1eJ7_border-left-style: solid;
  --mixin-GuGA5Dbb1eJ7_border-left-width: 1px;
  --mixin-GuGA5Dbb1eJ7_border-right-color: #dddddd;
  --mixin-GuGA5Dbb1eJ7_border-right-style: solid;
  --mixin-GuGA5Dbb1eJ7_border-right-width: 1px;
  --mixin-GuGA5Dbb1eJ7_border-top-color: #dddddd;
  --mixin-GuGA5Dbb1eJ7_border-top-style: solid;
  --mixin-GuGA5Dbb1eJ7_border-top-width: 1px;
  --mixin-GuGA5Dbb1eJ7_border-bottom-left-radius: 3px;
  --mixin-GuGA5Dbb1eJ7_border-bottom-right-radius: 3px;
  --mixin-GuGA5Dbb1eJ7_border-top-left-radius: 3px;
  --mixin-GuGA5Dbb1eJ7_border-top-right-radius: 3px;
  --mixin-GuGA5Dbb1eJ7_font-family: "Inconsolata";
  --mixin-GuGA5Dbb1eJ7_padding-bottom: 1px;
  --mixin-GuGA5Dbb1eJ7_padding-left: 4px;
  --mixin-GuGA5Dbb1eJ7_padding-right: 4px;
  --mixin-GuGA5Dbb1eJ7_padding-top: 1px;
  --mixin-GuGA5Dbb1eJ7_white-space: pre-wrap;
  --mixin-vSjx7byxQ8f-_border-left-color: #dddddd;
  --mixin-vSjx7byxQ8f-_border-left-style: solid;
  --mixin-vSjx7byxQ8f-_border-left-width: 3px;
  --mixin-vSjx7byxQ8f-_color: #888888;
  --mixin-vSjx7byxQ8f-_padding-left: 10px;
  --mixin-vSjx7byxQ8f-_white-space: pre-wrap;
  --mixin-xhsoOCR7qAjR_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-xhsoOCR7qAjR_border-bottom-color: #dddddd;
  --mixin-xhsoOCR7qAjR_border-bottom-style: solid;
  --mixin-xhsoOCR7qAjR_border-bottom-width: 1px;
  --mixin-xhsoOCR7qAjR_border-left-color: #dddddd;
  --mixin-xhsoOCR7qAjR_border-left-style: solid;
  --mixin-xhsoOCR7qAjR_border-left-width: 1px;
  --mixin-xhsoOCR7qAjR_border-right-color: #dddddd;
  --mixin-xhsoOCR7qAjR_border-right-style: solid;
  --mixin-xhsoOCR7qAjR_border-right-width: 1px;
  --mixin-xhsoOCR7qAjR_border-top-color: #dddddd;
  --mixin-xhsoOCR7qAjR_border-top-style: solid;
  --mixin-xhsoOCR7qAjR_border-top-width: 1px;
  --mixin-xhsoOCR7qAjR_border-bottom-left-radius: 3px;
  --mixin-xhsoOCR7qAjR_border-bottom-right-radius: 3px;
  --mixin-xhsoOCR7qAjR_border-top-left-radius: 3px;
  --mixin-xhsoOCR7qAjR_border-top-right-radius: 3px;
  --mixin-xhsoOCR7qAjR_font-family: "Inconsolata";
  --mixin-xhsoOCR7qAjR_padding-bottom: 3px;
  --mixin-xhsoOCR7qAjR_padding-left: 6px;
  --mixin-xhsoOCR7qAjR_padding-right: 6px;
  --mixin-xhsoOCR7qAjR_padding-top: 3px;
  --mixin-xhsoOCR7qAjR_white-space: pre-wrap;
  --mixin-FbnnkPvaNRNu_display: flex;
  --mixin-FbnnkPvaNRNu_flex-direction: column;
  --mixin-FbnnkPvaNRNu_align-items: stretch;
  --mixin-FbnnkPvaNRNu_justify-content: flex-start;
  --mixin-FbnnkPvaNRNu_list-style-position: outside;
  --mixin-FbnnkPvaNRNu_padding-left: 40px;
  --mixin-FbnnkPvaNRNu_position: relative;
  --mixin-FbnnkPvaNRNu_list-style-type: disc;
  --mixin-FbnnkPvaNRNu_white-space: pre-wrap;
  --mixin-1gFt9of3M9Ln_display: flex;
  --mixin-1gFt9of3M9Ln_flex-direction: column;
  --mixin-1gFt9of3M9Ln_align-items: stretch;
  --mixin-1gFt9of3M9Ln_justify-content: flex-start;
  --mixin-1gFt9of3M9Ln_list-style-position: outside;
  --mixin-1gFt9of3M9Ln_padding-left: 40px;
  --mixin-1gFt9of3M9Ln_position: relative;
  --mixin-1gFt9of3M9Ln_list-style-type: decimal;
  --mixin-1gFt9of3M9Ln_white-space: pre-wrap;
  --mixin-iJAhkx-inTq__color: #000000;
  --mixin-iJAhkx-inTq__font-size: 20px;
  --mixin-iJAhkx-inTq__font-weight: 600;
  --mixin-iJAhkx-inTq__line-height: 1.5;
  --mixin-iJAhkx-inTq__white-space: pre-wrap;
  --mixin-HfdBd9lYqCJV_color: #000000;
  --mixin-HfdBd9lYqCJV_font-size: 16px;
  --mixin-HfdBd9lYqCJV_font-weight: 600;
  --mixin-HfdBd9lYqCJV_line-height: 1.5;
  --mixin-HfdBd9lYqCJV_white-space: pre-wrap;
  --mixin-It8YB1byHIxT_color: #3291ff;
  --mixin-It8YB1byHIxT_white-space: pre-wrap;
  --mixin-fe51xqOw8sD6_white-space: pre-wrap;
  --mixin-hob5gRddBSWq_white-space: pre-wrap;
}

.plasmic_mixins {
  --mixin-2sC59OOCJFuL_box-shadow: 0px 8px 16px 4px #00000026;
  --plasmic-mixin-card-background-1_box-shadow: var(
    --mixin-2sC59OOCJFuL_box-shadow
  );
  --mixin-2sC59OOCJFuL_white-space: pre-wrap;
  --plasmic-mixin-card-background-1_white-space: var(
    --mixin-2sC59OOCJFuL_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-BmsHYC2Qg5jM_font-family);
  font-size: var(--mixin-BmsHYC2Qg5jM_font-size);
  font-weight: var(--mixin-BmsHYC2Qg5jM_font-weight);
  font-style: var(--mixin-BmsHYC2Qg5jM_font-style);
  color: var(--mixin-BmsHYC2Qg5jM_color);
  text-align: var(--mixin-BmsHYC2Qg5jM_text-align);
  text-transform: var(--mixin-BmsHYC2Qg5jM_text-transform);
  line-height: var(--mixin-BmsHYC2Qg5jM_line-height);
  letter-spacing: var(--mixin-BmsHYC2Qg5jM_letter-spacing);
  white-space: var(--mixin-BmsHYC2Qg5jM_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-GD1oTm4vG2YR_color);
  font-weight: var(--mixin-GD1oTm4vG2YR_font-weight);
  font-size: var(--mixin-GD1oTm4vG2YR_font-size);
  line-height: var(--mixin-GD1oTm4vG2YR_line-height);
  letter-spacing: var(--mixin-GD1oTm4vG2YR_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-Cyvgacm50yjX_color);
  font-size: var(--mixin-Cyvgacm50yjX_font-size);
  font-weight: var(--mixin-Cyvgacm50yjX_font-weight);
  letter-spacing: var(--mixin-Cyvgacm50yjX_letter-spacing);
  line-height: var(--mixin-Cyvgacm50yjX_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-nR0BcGDE1UIJ_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-JcaHpWWZngGr_color);
  font-size: var(--mixin-JcaHpWWZngGr_font-size);
  font-weight: var(--mixin-JcaHpWWZngGr_font-weight);
  line-height: var(--mixin-JcaHpWWZngGr_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-bvOR7Z94IU3Z_color);
  font-size: var(--mixin-bvOR7Z94IU3Z_font-size);
  font-weight: var(--mixin-bvOR7Z94IU3Z_font-weight);
  line-height: var(--mixin-bvOR7Z94IU3Z_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-GuGA5Dbb1eJ7_font-family);
  border-radius: var(--mixin-GuGA5Dbb1eJ7_border-top-left-radius)
    var(--mixin-GuGA5Dbb1eJ7_border-top-right-radius)
    var(--mixin-GuGA5Dbb1eJ7_border-bottom-right-radius)
    var(--mixin-GuGA5Dbb1eJ7_border-bottom-left-radius);
  padding: var(--mixin-GuGA5Dbb1eJ7_padding-top)
    var(--mixin-GuGA5Dbb1eJ7_padding-right)
    var(--mixin-GuGA5Dbb1eJ7_padding-bottom)
    var(--mixin-GuGA5Dbb1eJ7_padding-left);
  border-top: var(--mixin-GuGA5Dbb1eJ7_border-top-width)
    var(--mixin-GuGA5Dbb1eJ7_border-top-style)
    var(--mixin-GuGA5Dbb1eJ7_border-top-color);
  border-right: var(--mixin-GuGA5Dbb1eJ7_border-right-width)
    var(--mixin-GuGA5Dbb1eJ7_border-right-style)
    var(--mixin-GuGA5Dbb1eJ7_border-right-color);
  border-bottom: var(--mixin-GuGA5Dbb1eJ7_border-bottom-width)
    var(--mixin-GuGA5Dbb1eJ7_border-bottom-style)
    var(--mixin-GuGA5Dbb1eJ7_border-bottom-color);
  border-left: var(--mixin-GuGA5Dbb1eJ7_border-left-width)
    var(--mixin-GuGA5Dbb1eJ7_border-left-style)
    var(--mixin-GuGA5Dbb1eJ7_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-vSjx7byxQ8f-_color);
  padding-left: var(--mixin-vSjx7byxQ8f-_padding-left);
  border-left: var(--mixin-vSjx7byxQ8f-_border-left-width)
    var(--mixin-vSjx7byxQ8f-_border-left-style)
    var(--mixin-vSjx7byxQ8f-_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-xhsoOCR7qAjR_font-family);
  border-radius: var(--mixin-xhsoOCR7qAjR_border-top-left-radius)
    var(--mixin-xhsoOCR7qAjR_border-top-right-radius)
    var(--mixin-xhsoOCR7qAjR_border-bottom-right-radius)
    var(--mixin-xhsoOCR7qAjR_border-bottom-left-radius);
  padding: var(--mixin-xhsoOCR7qAjR_padding-top)
    var(--mixin-xhsoOCR7qAjR_padding-right)
    var(--mixin-xhsoOCR7qAjR_padding-bottom)
    var(--mixin-xhsoOCR7qAjR_padding-left);
  border-top: var(--mixin-xhsoOCR7qAjR_border-top-width)
    var(--mixin-xhsoOCR7qAjR_border-top-style)
    var(--mixin-xhsoOCR7qAjR_border-top-color);
  border-right: var(--mixin-xhsoOCR7qAjR_border-right-width)
    var(--mixin-xhsoOCR7qAjR_border-right-style)
    var(--mixin-xhsoOCR7qAjR_border-right-color);
  border-bottom: var(--mixin-xhsoOCR7qAjR_border-bottom-width)
    var(--mixin-xhsoOCR7qAjR_border-bottom-style)
    var(--mixin-xhsoOCR7qAjR_border-bottom-color);
  border-left: var(--mixin-xhsoOCR7qAjR_border-left-width)
    var(--mixin-xhsoOCR7qAjR_border-left-style)
    var(--mixin-xhsoOCR7qAjR_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-FbnnkPvaNRNu_display);
  flex-direction: var(--mixin-FbnnkPvaNRNu_flex-direction);
  align-items: var(--mixin-FbnnkPvaNRNu_align-items);
  justify-content: var(--mixin-FbnnkPvaNRNu_justify-content);
  list-style-position: var(--mixin-FbnnkPvaNRNu_list-style-position);
  padding-left: var(--mixin-FbnnkPvaNRNu_padding-left);
  position: var(--mixin-FbnnkPvaNRNu_position);
  list-style-type: var(--mixin-FbnnkPvaNRNu_list-style-type);
  flex-column-gap: var(--mixin-FbnnkPvaNRNu_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-1gFt9of3M9Ln_display);
  flex-direction: var(--mixin-1gFt9of3M9Ln_flex-direction);
  align-items: var(--mixin-1gFt9of3M9Ln_align-items);
  justify-content: var(--mixin-1gFt9of3M9Ln_justify-content);
  list-style-position: var(--mixin-1gFt9of3M9Ln_list-style-position);
  padding-left: var(--mixin-1gFt9of3M9Ln_padding-left);
  position: var(--mixin-1gFt9of3M9Ln_position);
  list-style-type: var(--mixin-1gFt9of3M9Ln_list-style-type);
  flex-column-gap: var(--mixin-1gFt9of3M9Ln_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-iJAhkx-inTq__color);
  font-size: var(--mixin-iJAhkx-inTq__font-size);
  font-weight: var(--mixin-iJAhkx-inTq__font-weight);
  line-height: var(--mixin-iJAhkx-inTq__line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-HfdBd9lYqCJV_color);
  font-size: var(--mixin-HfdBd9lYqCJV_font-size);
  font-weight: var(--mixin-HfdBd9lYqCJV_font-weight);
  line-height: var(--mixin-HfdBd9lYqCJV_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-It8YB1byHIxT_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
