.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  background: #171717;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.heroSection:global(.__wab_instance) {
  position: relative;
}
.carouselSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 200px;
  min-height: 140px;
  z-index: 2;
  margin-top: 122px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px;
}
.text__bCo9N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  text-align: center;
  min-width: 0;
  margin: 40px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__bCo9N {
    margin: 20px 0px;
  }
}
.valuesSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  z-index: 3;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  margin: 0px 0px 60px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .valuesSection {
    padding: 0px 8px;
  }
}
.columns___1ViPk {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns___1ViPk > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 60px);
  width: calc(100% + 60px);
  --plsmc-rc-col-gap: 60px;
}
.columns___1ViPk > :global(.__wab_flex-container) > *,
.columns___1ViPk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___1ViPk > :global(.__wab_flex-container) > picture > img,
.columns___1ViPk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 60px;
}
.columns___1ViPk > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns___1ViPk > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns___1ViPk > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___1ViPk {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___1ViPk > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 30px);
    height: calc(100% + 30px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___1ViPk > :global(.__wab_flex-container) > *,
  .columns___1ViPk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___1ViPk > :global(.__wab_flex-container) > picture > img,
  .columns___1ViPk
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 30px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___1ViPk > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___4Aeck {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.values1:global(.__wab_instance) {
  position: relative;
}
.column__uurNc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.values2:global(.__wab_instance) {
  position: relative;
}
.column__ckpCf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.values3:global(.__wab_instance) {
  position: relative;
}
.reveal___308Zu:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.toplineNumbersSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin-top: 80px;
  z-index: 60;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 0px 20px;
}
.line {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  max-width: 700px;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #ffffff;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
  border-width: 1px;
}
.freeBox__kqyVn {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1000px;
  min-width: 0;
  padding: 8px;
}
.text__urBNn {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 2.5em;
  font-weight: 500;
  margin-top: 15px;
  text-align: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__urBNn {
    font-size: 1.5em;
  }
}
.numbersContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  margin-top: 26px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
}
.numbersContainer > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
  --plsmc-rc-col-gap: 20px;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.numbersContainer > :global(.__wab_flex-container) > *,
.numbersContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.numbersContainer > :global(.__wab_flex-container) > picture > img,
.numbersContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
  margin-top: 40px;
}
.numbersContainer > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.numbersContainer > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .numbersContainer {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .numbersContainer > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .numbersContainer > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__vsCtF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__vsCtF {
    align-items: center;
    justify-content: flex-start;
  }
}
.toplineNumber__ngGqg:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.text__nZ0X1 {
  width: 100%;
  height: auto;
  max-width: 100%;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  min-width: 0;
}
.column___98MjE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.toplineNumber__mksh:global(.__wab_instance) {
  position: relative;
}
.text___4V17F {
  width: 100%;
  height: auto;
  max-width: 100%;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  min-width: 0;
}
.column___6VUym {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.toplineNumber__p9RxJ:global(.__wab_instance) {
  position: relative;
}
.text__lql6R {
  width: 100%;
  height: auto;
  max-width: 100%;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  min-width: 0;
}
.column__r8S6S {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.toplineNumber___7Oj2Y:global(.__wab_instance) {
  position: relative;
}
.text__uLm3K {
  width: 100%;
  height: auto;
  max-width: 100%;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  min-width: 0;
}
.freeBox__dIux {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.reveal__aniNc:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.freeBox__rZmrp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 80px;
  min-width: 0;
}
.freeBox___7IKJf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.reveal__i80Zd:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.freeBox__o53Ml {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1400px;
  margin-top: 100px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.solutionsSection:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.testamonialSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  z-index: 500;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .testamonialSection {
    margin-top: 70px;
    margin-bottom: 100px;
  }
}
.sliderCarousel:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  object-fit: cover;
  position: relative;
  height: auto;
  display: flex;
  min-width: 0;
}
.freeBox___5ZrBs {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.testamonialComponent__yMl8O:global(.__wab_instance) {
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .testamonialComponent__yMl8O:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.freeBox__tppSr {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.testamonialComponent__cymlg:global(.__wab_instance) {
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .testamonialComponent__cymlg:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.text__qgVzA {
  padding-left: 0px;
}
.pressSection {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  display: none;
}
.freeBox__hX6K {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.columns__tsU3S {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
}
.columns__tsU3S > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__tsU3S > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__tsU3S > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tsU3S {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tsU3S > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___8L414 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.newsItem__pamBy:global(.__wab_instance) {
  position: relative;
}
.column__re3PJ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.newsItem__u5NZ:global(.__wab_instance) {
  position: relative;
}
.column___5Mein {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.newsItem__xUHp:global(.__wab_instance) {
  position: relative;
}
.freeBox__qbmO8 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.reveal__wizle:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.freeBox___5OOiw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin-top: 60px;
  grid-column-start: 3 !important;
  grid-column-end: -3 !important;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___5OOiw {
    margin-top: 91px;
  }
}
.columns__hoyfu {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__hoyfu > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
}
.columns__hoyfu > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__hoyfu > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 9 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__hoyfu {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__hoyfu > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 40px);
    height: calc(100% + 40px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__hoyfu > :global(.__wab_flex-container) > *,
  .columns__hoyfu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__hoyfu > :global(.__wab_flex-container) > picture > img,
  .columns__hoyfu
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 40px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__hoyfu > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__mlooL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  height: 50px;
  margin-top: 30px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: contain;
}
.column__a9DdB {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.faqAccordion {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.reveal__slhxO:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.ctaParent:global(.__wab_instance) {
  position: relative;
  margin: 100px 0px 40px;
}
.footer:global(.__wab_instance) {
  position: relative;
  margin-top: 101px;
  margin-bottom: 20px;
}
