.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.columns__u0Lo {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__u0Lo > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 7 / 12);
}
.columns__u0Lo > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__u0Lo {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__u0Lo > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__zsnqg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.columns__i5LDu {
  display: flex;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 100%;
  flex-direction: row;
  position: relative;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__i5LDu > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 15px);
  width: calc(100% + 15px);
  --plsmc-rc-col-gap: 15px;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.columns__i5LDu > :global(.__wab_flex-container) > *,
.columns__i5LDu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__i5LDu > :global(.__wab_flex-container) > picture > img,
.columns__i5LDu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 15px;
  margin-top: 15px;
}
.columns__i5LDu > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__i5LDu > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__i5LDu > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__i5LDu {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__i5LDu > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__i5LDu > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__hLEkI {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.marketValidation:global(.__wab_instance) {
  position: relative;
}
.column__hrTff {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.audienceIdentification:global(.__wab_instance) {
  position: relative;
}
.column__cJd79 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.conceptIdentification:global(.__wab_instance) {
  position: relative;
}
.column__lypD2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.marketingStrategy:global(.__wab_instance) {
  position: relative;
}
.column__prxvA {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.competitorAnalysis:global(.__wab_instance) {
  position: relative;
}
.column__xH883 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.expansionStrategy:global(.__wab_instance) {
  position: relative;
}
.text {
  height: auto;
}
.featuresColumn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.featuresPanel:global(.__wab_instance) {
  position: relative;
  margin-top: 30px;
  transition-property: all;
  transition-duration: 0.7s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.7s;
}
