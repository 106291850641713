.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  position: relative;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px;
}
.freeBox__mj67W {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  min-width: 0;
  min-height: 0;
}
.freeBox__ccxfr {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #171717;
  padding: 15px 24px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ccxfr {
    padding: 10px 15px 4px;
  }
}
.navBarCustom:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.heroCenterContainer:global(.__wab_instance) {
  position: relative;
  min-height: 100vh;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroCenterContainer:global(.__wab_instance) {
    margin-bottom: 80px;
  }
}
.freeBox__kCcDf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  z-index: 40;
  margin-bottom: 0px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__kCcDf {
    padding: 8px 15px;
  }
}
.freeBox__bVc5A {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 500px;
  background: #232323;
  min-width: 0;
  border-radius: 24px;
  padding: 24px 40px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__bVc5A {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.text__g5Rl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 300;
  min-width: 0;
}
.text__ft3Go {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  text-transform: none;
  line-height: 2;
  font-weight: 300;
  min-width: 0;
}
