.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  position: relative;
}
.freeBox__r8Vf4 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
}
.freeBox__r8Vf4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 3px);
  width: calc(100% + 3px);
}
.freeBox__r8Vf4 > :global(.__wab_flex-container) > *,
.freeBox__r8Vf4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__r8Vf4 > :global(.__wab_flex-container) > picture > img,
.freeBox__r8Vf4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 3px;
}
.freeBox__mfjjc {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__mfjjc {
    padding: 0px;
  }
}
.text__aHezP {
  width: auto;
  height: auto;
  max-width: 100%;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-size: 6em;
}
.million {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  font-size: 6em;
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
  transform: translateX(0px) translateY(3px) translateZ(0px);
  display: none;
}
.millionshowMillion {
  display: block;
}
.freeBox__lMrWg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  display: none;
}
.slotTargetAppended {
  color: #ffffff;
  font-size: 4em;
  font-weight: 800;
  line-height: 1;
}
.freeBox__zuCmL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: none;
}
.freeBox__zuCmL > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__zuCmL > :global(.__wab_flex-container) > *,
.freeBox__zuCmL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zuCmL > :global(.__wab_flex-container) > picture > img,
.freeBox__zuCmL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.freeBox__qKa4R {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
.slotTargetMetric {
  color: #ffffff;
  text-transform: uppercase;
}
.slotTargetItemTitle {
  color: #ffffff;
  text-transform: uppercase;
}
