.root {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
}
.frame1000003185 {
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  background: #373737;
  min-height: 380px;
  transition-property: all;
  transition-duration: 0.3s;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid #373737;
}
.frame1000003185 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.frame1000003185 > :global(.__wab_flex-container) > *,
.frame1000003185 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003185 > :global(.__wab_flex-container) > picture > img,
.frame1000003185
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.root .frame1000003185:hover {
  transform: translateX(0px) translateY(-4px) translateZ(0px);
  border: 1px solid #f8d252;
}
.freeBox__fcqTa {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.img {
  object-fit: contain;
  max-width: 100%;
  height: 70px;
}
.img > picture > img {
  object-fit: contain;
}
.frame1000003165 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.frame1000003165 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 12.807px);
  height: calc(100% + 12.807px);
}
.frame1000003165 > :global(.__wab_flex-container) > *,
.frame1000003165 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame1000003165 > :global(.__wab_flex-container) > picture > img,
.frame1000003165
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12.807px;
}
.freeBox___7YGaN {
  display: flex;
  align-self: stretch;
  position: relative;
  flex-direction: row;
  min-height: 94px;
}
.slotTargetTitle {
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.03em;
  line-height: 1.3;
}
.freeBox__rjs8I {
  display: flex;
  align-self: stretch;
  position: relative;
  flex-direction: row;
  min-height: 90px;
}
.slotTargetSubtitle {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 300;
  color: #f9f9f9;
  line-height: 1.58;
  letter-spacing: 0em;
}
.getStartedWithArrow:global(.__wab_instance) {
  position: relative;
  width: auto;
  left: auto;
  z-index: 1;
  align-self: auto;
  top: auto;
  right: auto;
  bottom: auto;
}
