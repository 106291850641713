.drawer-link {
    display: block;
    margin-bottom: 1rem;
    color: #ffffff;
    font-size: 2.8em; /* Updated font size to 48 pixels */
    text-decoration: none;
    font-family: 'Poppins', sans-serif; /* Added Poppins font */
    font-weight: 400; /* Semi-bold, Poppins doesn't have a 500 weight, so 600 is used */
}
.drawer-link:hover {
    color: #FFA500; /* Change color on hover */
    font-weight: 400;
}

/* Media query for screens with a maximum width of 767px (typical for mobile screens) */
@media screen and (max-width: 767px) {
    .drawer-link {
        font-size: 18px; /* Font size for mobile screens */
    }
}

@media screen and (min-width: 1700px) {
    .drawer-link {
        font-size: 3.3em; /* Font size for larger screens */
    }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.spinning {
    animation: spin 0.5s ease-out;
}


.close-button {
    /*color: black;*/
    /*background-color: #FDFCF8;*/
    background-color: unset;
    padding: 0px!important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    border-radius: 18px;
    cursor: pointer;
    text-decoration: none;
    border: none; /* To remove default border of button */
}

.close-icon {
    /*color: #FFFFFF;*/
    font-size: 26px;
}
.close-icon:hover {
    color: #FFA500;
}

.social-media-icons a {
    margin-right: 10px; /* Add spacing between icons (adjust as needed) */
}

.social-media-icons a:last-child {
    margin-right: 0; /* Remove margin for the last icon */
}

.social-media-icons .icon {
    color: white;
    width: 30px;
    height: 30px
}

.social-media-icons .icon:hover {
    color: #FFA500;
}

